import AppColors from "../../constants/AppColors"

export const AUDIT_SHEET_HEADER = [
    'Encounter #',
    'Rendering',
    'Enc Dt',
    'Billed Code',
    'RVU',
    '$ value',
    'Response',
    'Agree',
    'Disagree',
    'Audited Code',
    'RVU',
    '$ value',
    'Notes'
]
export const AUDIT_SHEET_KEY = [
    'encounter_no',
    'rendering',
    'enc_dt',
  
    // new keys
    'billed_icd',
    'srvcs_no',
    'billed_modifier',
  
    'provider_rvu',
    'provider_dollar_value',
    'response',
    'agree',
    'disagree',
  
    // new key
    'audited_icd',
    // 'audited_code',
    'audited_cpt',
    'audited_modifier',
  
    'audited_rvu',
    'audited_dollar_value',
    'notes',
  
    //default columns
  ];
export const STATUS = ['RESOLVED', 'RE-OPENED', 'REBUTTAL-RESOLVED']
export const STATUS_MESSAGE = {
    'RE-OPENED': 'Re-opened',
    'RESOLVED': 'Marked as resolved',
    'REBUTTAL-RESOLVED': 'Marked as resolved'

}
export const RESOLVED_STATUS = ['RESOLVED', 'REBUTTAL-RESOLVED']

export const REBUTTAL_STATUS = 'REBUTTAL'
export const OPEN_STATUS = 'OPEN'

export const defaultStyle = {
    control: {
        backgroundColor: '#fff',
        fontSize: 14,
        fontWeight: 'normal',
    },

    '&multiLine': {
        control: {
            fontFamily: 'pt sans',
            minHeight: 35,
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
        },
        input: {
            padding: 9,
            border: '1px solid silver',
            borderRadius: '10px',

        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            fontSize: 14,
            fontWeight: 'bold',
            minWidth: '250px',
            borderRadius: '10px',
            color: AppColors.secondary,
            maxHeight: '200px',
            overflowY: 'scroll'
        },
        item: {
            padding: 10,
            // borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}

export const NUMERIC_KEYS = [8, 9, 13, 46, 116, 123, 189, 190, 191]
export const RENDERING_AS_COL = 1
export const DOLLAR_COL = [5, 11]
export const AGREE_DISAGREE_COL = [7, 8]
export const RESPONSE_AS_COL = 6