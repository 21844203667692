import EditCommentModal from './EditCommentModal';
import { useBoolean } from '@prasanna-transcend/code-quick-components';
import DeleteCommentModal from './DeleteCommentModal';
import CommentBox from './CommentBox';
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useContext, useState } from 'react';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import AppColors from '../../../../constants/AppColors';

const CommentList = ({ onOpenNotes }) => {
  const auditSheetContext = useContext(AuditSheetContext);
  const { allComments, isAllCommentLoading, commentFilters } =
    auditSheetContext;
  const [isEditCommentOpen, iecoState] = useBoolean(false);
  const [isDeleteCommentOpen, idcoState] = useBoolean(false);
  const [selectedComment, setSelectedComment] = useState({});
  const [selectedParentComment, setSelectedParentComment] = useState({});

  const renderCommentLoading = () => {
    return [0, 1, 2, 3, 5].map(p => (
      <Stack mb={5} key={`${p}-loader`}>
        <Skeleton height="100px" />
      </Stack>
    ));
  };

  if (isAllCommentLoading) {
    return renderCommentLoading();
  }

  if (!allComments.length) {
    return (
      <Box
        height={'100%'}
        display="flex"
        alignItems={'center'}
        justifyContent="center"
      >
        <Text color={AppColors.primary}>No Notes & Activity here yet!</Text>
      </Box>
    );
  }

  return (
    <>
      <HStack justifyContent="flex-end">
        <Menu>
          <MenuButton
            px={4}
            py={2}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            mb={2}
          >
            {commentFilters.sheet_name ? 'Current Sheet' : 'All Sheets'}{' '}
            <ChevronDownIcon />
          </MenuButton>
          <MenuList padding={0}>
            <MenuItem
              onClick={() => {
                onOpenNotes({ current: true });
              }}
              height={'50px'}
              fontSize={'16px'}
            >
              Current Sheet
            </MenuItem>
            <MenuItem
              onClick={() => {
                onOpenNotes({ all: true });
              }}
              height={'50px'}
              fontSize={'16px'}
            >
              All Sheets
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      {allComments.length
        ? allComments.map((comment, index) => {
            return (
              <CommentBox
                comment={comment}
                index={index}
                onEditModalOpen={iecoState.on}
                onDeleteModalOpen={idcoState.on}
                setSelectedComment={setSelectedComment}
                selectedComment={selectedComment}
                setSelectedParentComment={setSelectedParentComment}
              />
            );
          })
        : null}
      <EditCommentModal
        isOpen={isEditCommentOpen}
        onClose={() => {
          iecoState.off();
        }}
        selectedComment={selectedComment}
        selectedParentComment={selectedParentComment}
      />
      <DeleteCommentModal
        isOpen={isDeleteCommentOpen}
        onClose={() => {
          idcoState.off();
        }}
        selectedComment={selectedComment}
        selectedParentComment={selectedParentComment}
      />
    </>
  );
};
export default CommentList;
