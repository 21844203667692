import { toTitleCase } from '../../../utils/common.utils';
import { dynamicAuditColumnHeader } from './common';

/**
 *
 * @param {String} key
 */

function prepareKey(key) {
  if (key.match('srvcs_no')) {
    const k = key.replace(/srvcs_no/i, 'billed_cpt');

    const columnKey = k.replace(/_/g, ' ').split(' ');

    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const columnKey = key.replace(/_/g, ' ').split(' ');

  const replaceStr = columnKey.map((w, i) => {
    if (i === 0) {
      return toTitleCase(w);
    } else if (w === 'icd' || w === 'cpt') {
      return w.toUpperCase();
    } else {
      return toTitleCase(w);
    }
  });

  return replaceStr.join(' ');
}

/**
 *
 * @param {Array} columns
 */

function sortColumns(columns) {
  return columns.sort((a, b) => {
    const aNum = parseInt(a.match(/\d+/));
    const bNum = parseInt(b.match(/\d+/));

    if (isNaN(aNum) && isNaN(bNum)) {
      return 0;
    } else if (isNaN(aNum)) {
      return -1;
    } else if (isNaN(bNum)) {
      return 1;
    }

    return aNum - bNum;
  });
}

/**
 *
 * @param {Array} headers
 */

function arrangeHeader(headers) {
  const firstSetColumns = ['Encounter #', 'Rendering', 'Enc Dt'];

  const billedIcdColumns = [];

  const billedCptColumns = [];

  const billedModifierColumns = [];

  const agreeColumns = ['RVU', '$ value', 'Response', 'Agree', 'Disagree'];

  const auditedIcdColumns = [];

  const auditedCptColumns = [];

  const auditedModifier = [];

  const auditedRvuValues = ['RVU', '$ Value', 'Notes'];

  const lastSetColumns = [
    'Provider Education',
    'Number & Complexity',
    'Amount & Complexity',
    'Mortality of PatientManagement',
  ];

  headers.forEach(h => {
    if (h.match('Billed ICD')) {
      billedIcdColumns.push(h);
    } else if (h.match('Billed CPT')) {
      billedCptColumns.push(h);
    } else if (h.match('Billed Modifier')) {
      const header = h.replace(/^Billed\s+/i, '');
      billedModifierColumns.push(header);
    } else if (h.match('Audited ICD')) {
      auditedIcdColumns.push(h);
    } else if (h.match('Audited CPT')) {
      auditedCptColumns.push(h);
    } else if (h.match('Audited Modifier')) {
      const header = h.replace(/^Audited\s+/i, '');
      auditedModifier.push(header);
    }
  });

  const billedColumns = [
    ...sortColumns(billedIcdColumns),
    ...sortColumns(billedCptColumns),
    ...sortColumns(billedModifierColumns),
  ];
  const auditedColumns = [
    ...sortColumns(auditedIcdColumns),
    ...sortColumns(auditedCptColumns),
    ...sortColumns(auditedModifier),
  ];

  const sortedHeaders = [
    ...firstSetColumns,
    ...billedColumns,
    ...agreeColumns,
    ...auditedColumns,
    ...auditedRvuValues,
    ...lastSetColumns,
  ];

  return sortedHeaders;
}

/**
 *
 * @param {Array} sheetData
 *
 */
export function prepareAuditSheetHeader(sheetData) {
  const headers = [];

  [sheetData[0]].forEach(sd => {
    if (sd && Object.keys(sd)) {
      Object?.keys(sd).forEach(key => {
        if (
          key !== 'file_name' &&
          key !== 'id' &&
          key !== 'row_id' &&
          key !== 'chart_id'
        ) {
          if (dynamicAuditColumnHeader[key]) {
            headers.push(dynamicAuditColumnHeader[key]);
          } else {
            const newKey = prepareKey(key);
            headers.push(newKey);
          }
        }
      });
    }
  });

  return arrangeHeader(headers);
}
