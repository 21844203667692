import { Box } from '@chakra-ui/react';
import { useCQMediaQuery } from '@prasanna-transcend/code-quick-components';
import React from 'react';

const CQContainer = props => {
  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  return (
    <Box
      // width={'100%'}
      py={30}
      px={30}
      mx={marginHorizontal}
      {...props}
    />
  );
};

CQContainer.propTypes = {
  ...Box.propTypes,
};

export default CQContainer;
