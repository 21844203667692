export const createEmptyTablesProps = currentUpload => {
  return [
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: [
        {
          file_name: currentUpload?.upload_id,
          chart_id: currentUpload.id,
          row_id: 1,

          id: null,

          encounter_no: null,
          rendering: { first_name: '', last_name: '', id: '' },
          enc_dt: null,

          additional_attributes: {
            billed_icd: null,

            audited_icd: null,

            provider_education: null,
            number_complexity: null,
            amount_complexity: null,
            risk_complications: null,
          },

          srvcs_no: null,
          billed_modifier: null,

          provider_rvu: null,
          provider_dollar_value: null,
          response: null,
          agree: null,
          disagree: null,

          audited_code: null, //audited_cpt
          audited_modifier: null,

          audited_rvu: null,
          audited_dollar_value: null,
          notes: null,
        },
      ],
    },
  ];
};

/**
 *
 * @param {Array} data
 * @returns { data with adtional_attribues }
 */

function haveAddionalAttributes(data) {
  const noAddionalAttributes = data.every(
    obj => obj.additional_attributes === null
  );

  if (noAddionalAttributes) {
    return data.map(d => {
      const tempObj = {
        ...d,
        additional_attributes: {
          provider_education: null,
          number_complexity: null,
          amount_complexity: null,
          risk_complications: null,
          audited_icd: null,
          billed_icd: null,
        },
      };
      return tempObj;
    });
  } else {
    return data;
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with srvcs Column }
 */

function isHaveSrvcsNoColumn(response) {
  const isEmptySrvcsColumn = response.every(obj => obj?.srvcs_no === null);

  if (isEmptySrvcsColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('srvcs_no_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            srvcs_no_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPT column }
 */

function isHaveBilledICDColumn(response) {
  const havebilledICD = response
    .map(obj => {
      return obj.additional_attributes?.hasOwnProperty('billed_icd');
    })
    .every(Boolean);

  const isEmptyBilledIcdColumn = response.every(
    obj => obj?.additional_attributes?.billed_icd === null
  );

  if (!havebilledICD) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          billed_icd: res.additional_attributes?.billed_icd
            ? res.additional_attributes?.billed_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyBilledIcdColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res?.additional_attributes?.hasOwnProperty('billed_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_icd_2: res.additional_attributes?.billed_icd_2
              ? res.additional_attributes?.billed_icd_2
              : null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPTColumn }
 */

function isHaveBilledModifierColumn(response) {
  const isEmptyBilledModifierColumn = response.every(
    obj => obj?.billed_modifier === null
  );

  if (isEmptyBilledModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('billed_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_modifier_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns
 */
function isHAveAuditedCodeColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_code === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_cpt_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_cpt_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedcptColumn }
 */
function isHaveAuditedCPTColumn(response) {
  const haveAuditedCpt = response
    .map(obj => {
      return obj.additional_attributes?.hasOwnProperty('audited_icd');
    })
    .every(Boolean);

  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.additional_attributes?.audited_icd === null
  );

  if (!haveAuditedCpt) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          audited_icd: res.additional_attributes?.audited_icd
            ? res.additional_attributes?.audited_icd
            : null,
          ...res.additional_attributes,
        },
      };
    });
  } else if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            audited_icd_2: res.additional_attributes?.audited_icd_2
              ? res.additional_attributes?.audited_icd_2
              : null,
            ...res?.additional_attributes,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedModifier }
 */
function isHaveAuditedModifierColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_modifier === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_modifier_2: null,
          },
        };
      }
    });
  }
}

export function createEmptyColumn(response) {
  const _haveAddionalAttributes = haveAddionalAttributes(response);

  // billed
  const _isHaveSrvcsNoColumn = isHaveSrvcsNoColumn(_haveAddionalAttributes);

  const _isHaveBilledCPTColumn = isHaveBilledICDColumn(_isHaveSrvcsNoColumn);

  const _isHaveBilledModifierColumn = isHaveBilledModifierColumn(
    _isHaveBilledCPTColumn
  );

  // audited
  const _isHAveAuditedCodeColumn = isHAveAuditedCodeColumn(
    _isHaveBilledModifierColumn
  );

  const _isHaveAuditedCPTColumn = isHaveAuditedCPTColumn(
    _isHAveAuditedCodeColumn
  );

  const _isHaveAuditedModifierColumn = isHaveAuditedModifierColumn(
    _isHaveAuditedCPTColumn
  );

  return _isHaveAuditedModifierColumn;
}
