
export const firstSetColumnKeyOrder = ['encounter_no', 'rendering', 'enc_dt'];

export const billedIctColumn = ['srvcs_no'];

export const billedCptColumn = ['billed_cpt'];

export const billedModifierColumn = ['billed_modifier'];

export const rvuColumnsOrder = [
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
];

export const auditedIctColumn = ['audited_icd'];

export const auditedCptColumn = ['audited_cpt'];

export const auditedModifierColumn = ['audited_modifier'];

export const auditedRuvColumns = [
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];

export const defaultColumns = [
  'provider_education',
  'number_complexity',
  'amount_complexity',
  'risk_complications',
];

export const addionalAttributesKeys = [
  'srvcs_no_',

  'billed_cpt',
  'billed_cpt_',

  'billed_modifier',
  'billed_modifier_',

  'audited_icd_',

  'audited_cpt',
  'audited_cpt_',

  'audited_modifier',
  'audited_modifier_',

  ...defaultColumns,
];

export const defaultDataProps = ['file_name', 'chart_id', 'id', 'row_id'];


export const dynamicColumnKeywords = ["srvcs_no", "billed_icd", "billed_modifier", "audited_icd", "audited_cpt", "audited_modifier"];

export const DYNAMIC_AUDIT_SHEET_KEY = [
    'encounter_no',
    'rendering',
    'enc_dt',
  
    'srvcs_no',
    'billed_modifier',
  
    'provider_rvu',
    'provider_dollar_value',
    'response',
    'agree',
    'disagree',
  
    // 'audited_icd',
  
    'audited_code',
  
    'audited_cpt',
    'audited_modifier',
  
    'audited_rvu',
    'audited_dollar_value',
    'notes',
  
    'row_id',
    'file_name',
    'chart_id',
    'id',
  ];