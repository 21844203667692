/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { MyAuditContext } from '../../../../providers/MyAuditProvider';
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  useCQMediaQuery,
} from '@prasanna-transcend/code-quick-components';
import { RebuttalIcon, UrgentIcon } from '../../../../constants/IconData';
import { format, intlFormatDistance } from 'date-fns';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { toTitleCase } from '../../../../utils/common.utils';
import { renderActionComponent } from '../DashboardComponents';
import { useNavigate } from 'react-router-dom';
import {
  getAuditStatus,
  MY_AUDITS_ORDERING,
} from '../../../../constants/constants';

const columnHelper = createColumnHelper();

function Urgent() {
  const navigate = useNavigate();
  const auditContext = useContext(MyAuditContext);
  const [ordering, setOrdering] = useState('');
  const { getAuditUrgent } = auditContext;

  useEffect(() => {
    getAuditUrgent.loadAPI();
  }, [auditContext.filterParams, getAuditUrgent.showOrdering]);

  useEffect(() => {
    if (MY_AUDITS_ORDERING.includes(ordering)) {
      getAuditUrgent.setParams({ ordering });
    }
  }, [ordering]);
  let columns = useMemo(() => [
    columnHelper.accessor('upload_id', {
      header: () => '',
      enableSorting: false,
      cell: info => {
        return (
          <HStack justifyContent={'center'} alignItems={'center'} spacing={10}>
            {info.row.original?.status === 'CLIENT REBUTTAL' ||
            info.row.original?.status === 'QA REBUTTAL' ? (
              <RebuttalIcon style={{ height: 20, width: 20 }} />
            ) : (
              <UrgentIcon />
            )}
          </HStack>
        );
      },
    }),

    columnHelper.accessor('upload_id', {
      header: () => 'Upload #',
      id: 'chart_id',
      cell: info => {
        return info.renderValue()
      },
    }),

    columnHelper.accessor(row => row.uploaded_date, {
      id: 'upload_date',
      header: () => 'Chart Upload',
      cell: info => {
        const cellValue = info.getValue();
        if (!cellValue) {
          return 'N/A';
        }
        return (
          <HStack justifyContent={'center'}>
            <Text ml={3}>{format(new Date(cellValue), 'MM/dd/yyyy')}</Text>
          </HStack>
        );
      },
    }),

    columnHelper.accessor('audited_date', {
      header: () => 'Audit Upload',
      id: 'audited_date',
      cell: info => {
        const auditUpload = info.getValue();
        if (!auditUpload) {
          return 'N/A';
        }
        return (
          <HStack justifyContent={'center'}>
            <Text ml={3}>{format(new Date(auditUpload), 'MM/dd/yyyy')}</Text>
          </HStack>
        );
      },
    }),

    columnHelper.accessor(p => p.specialties?.name, {
      header: () => 'Specialties',
      id: 'specialty__name',
      cell: info => {
        const specialties = info.row.original?.specialties?.name;
        if (!specialties) {
          return 'N/A';
        }
        return <HStack  justifyContent={"center"}>
          <Text>{specialties === 'ent' ? 'ENT' : toTitleCase(specialties)}</Text>{}
        </HStack>

      },
    }),

    columnHelper.accessor(p => p.assigned_auditor?.first_name, {
      header: () => 'Auditor',
      id: 'auditor',
      cell: info => {
        const qa = info.row.original?.assigned_auditor;
        if (!qa) {
          return 'N/A';
        }
        return toTitleCase(`${qa?.first_name} ${qa?.last_name}`);
      },
    }),

    columnHelper.accessor('status', {
      header: () => 'Status',
      id: 'status',
      cell: info => {
        const status = info.row.original?.status;
        if (!status) {
          return 'N/A';
        }
        return getAuditStatus(status);
      },
    }),

    columnHelper.accessor('updated_date', {
      header: () => 'Last Updated',
      id: 'updated_date',
      cell: info => {
        let updatedAt = info.getValue();

        updatedAt = updatedAt
          ? intlFormatDistance(new Date(updatedAt), new Date(), {
              addSuffix: true,
            })
          : 'N/A';

        return (
          <Text>
            {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
          </Text>
        );
      },
    }),

    columnHelper.accessor('actions', {
      header: () => <Text ml={-10}>Actions</Text>,
      enableSorting: false,
      cell: info => {
        return renderActionComponent(info.row.original, navigate, auditContext);
      },
    }),
  ]);

  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  function handlePreviousPage() {
    auditContext.getAuditUrgent.loadPrevious();
  }

  function handleNextPage() {
    auditContext.getAuditUrgent.loadNext();
  }

  function handleSizeChange(size) {
    auditContext.getAuditUrgent({ size });
  }

  return (
    <>
      <Box
        // pointerEvents={isUpdating ? 'none' : 'inherit'}
        overflowX={canScroll ? 'scroll' : 'inherit'}
      >
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isAuditUploadListLoading}
          data={auditContext.auditUrgentList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(auditContext.getAuditUrgent)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </>
  );
}

export default Urgent;
