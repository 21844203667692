/**
 *
 * @param {Array} column
 */

export function findHighlightedColumn(column) {
    const highlightedIndices = [];
  
    column?.forEach(({ data }, i) => {
      if (data === 'provider_rvu') {
        highlightedIndices.push(i);
      } else if (data === 'provider_dollar_value') {
        highlightedIndices.push(i);
      } else if (data === 'audited_rvu') {
        highlightedIndices.push(i);
      } else if (data === 'audited_dollar_value') {
        highlightedIndices.push(i);
      }
    });
  
    return highlightedIndices;
  }
  
  export function findDollorColumn(column) {
    const dolloarColumnIndices = [];
  
    column.forEach(({ data }, i) => {
      if (data === 'provider_dollar_value') {
        dolloarColumnIndices.push(i);
      } else if (data === 'audited_dollar_value') {
        dolloarColumnIndices.push(i);
      }
    });
  
    return dolloarColumnIndices;
  }

  export function getAuditSheetKeys(columnData) {
    return columnData.map(({ data }, i) => {
      return data;
    });
  }

  export function findAgreeDisAgreeColumn(columnData) {
    const agreeDisAgreeIndices = [];
    columnData.forEach(({ data }, i) => {
      if (data === 'agree') {
        agreeDisAgreeIndices.push(i);
      } else if (data === 'disagree') {
        agreeDisAgreeIndices.push(i);
      }
    });
  
    return agreeDisAgreeIndices;
  }

  /**
 *
 * @param {Array} columndata
 */

export function findNumericColumns(columndata) {
    const numericColumns = [
      'provider_rvu',
      'provider_dollar_value',
      'audited_rvu',
      'audited_dollar_value',
    ];
  
    const indices = columndata.reduce((acc, cd, i) => {
      if (numericColumns.includes(cd.data)) {
        acc.push(i);
      }
      return acc;
    }, []);
  
    return indices;
  }

  /**
 *
 * @param {Array} columndata
 */
export function findResponseColumnIndex(columndata) {
    //const responseColumnIndices=columndata.find()
    const responseColumnIndices = columndata.reduce((acc, cd, i) => {
      if (cd.data === 'response') {
        acc = i;
      }
  
      return acc;
    }, null);
  
    return responseColumnIndices;
  }