/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.min.css';
import {
  NUMERIC_KEYS,
  RENDERING_AS_COL,
} from '../constants';
import { registerAllModules } from 'handsontable/registry';
import '../styles.css';
import { useBoolean } from '@chakra-ui/react';
import { useContext } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { useInterval } from '../../../hooks';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import { getRowColArray, getRowColIndex } from '../getRowColumn';
import AppColors from '../../../constants/AppColors';
import { registerCustomCells } from './hands_on_table_renderers/CustomRenderers';
import { format } from 'date-fns';
import { toTitleCase } from '../../../utils/common.utils';
import { prepareAuditSheetHeader } from '../utils/prepareAuditSheetHeader';
import { prepareAuditSheetColumn } from '../utils/prepareAuditSheetColumn';
import {
  findAgreeDisAgreeColumn,
  findDollorColumn,
  findHighlightedColumn,
  findNumericColumns,
  findResponseColumnIndex,
  getAuditSheetKeys,
} from '../utils/findColumnProps';

registerCustomCells({});

function getScaledRowHeight(scale) {
  return `${24 * scale}px`;
}

function getScaledHeaderFontSize(scale) {
  return 13 * scale + 'px';
}

function getScaledRowFontSize(scale) {
  return 14 * scale + 'px';
}

const AuditSheet = props => {
  //   refs
  const hotRef = useRef(null);

  /**
   *  { updateSheet } update the data in setState(setSheet);
   *  { updateAuditSheet } create audit sheet(POST) and getSheets and update sheet;
   *  { sheet } gives a current sheet not sheets
   */

  // props
  const { scale = 1, sheet, uploadId, view } = props;
  const { updateAuditSheet, updateSheet, onCommentClick } = props;
  const { closeComment, height, removeRows } = props;

  // booleans
  const [isProviderDollarDisabled, ipddState] = useBoolean(false);
  const [isAuditedDollarDisabled, iaddState] = useBoolean(false);

  // context data
  const auditSheetContext = useContext(AuditSheetContext);
  const {
    industryCodes,
    getComments,
    commentFlags,
    providerOptions,
    currentUpload,
  } = auditSheetContext;

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  // states

  const [sheetData, setSheetData] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);

  const [columnData, setColumnData] = useState([]);
  const [auditSheetHeaders, setAuditSheetHeaders] = useState([]);

  const [numericColumns, setNumericColumns] = useState([]);
  const [responseColumn, setResponseColumn] = useState(null);

  const [highlightedColumn, setHilightedColumn] = React.useState([]);

  const [dollarColumn, setDollorColumn] = React.useState([]);

  const [auditSheetColumnsKeys, setAuditSheetColumnsKeys] = React.useState([]);

  const [agreeDisAgreeCol, setAgreeDisAgreeCol] = React.useState([]);

  //   other hooks
  const toast = useToastr();

  useInterval(() => {
    //call update audit sheet
    if (!view) {
      updateAuditSheet();
    }
  }, 50000);

  registerAllModules();

  //   table props
  Handsontable.renderers.registerRenderer(
    'customStylesRenderer',
    (hotInstance, TD, ...rest) => {
      Handsontable.renderers.getRenderer('text')(hotInstance, TD, ...rest);
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
    }
  );
  Handsontable.renderers.registerRenderer(
    'agreeRenderer',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('text')(
        hotInstance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
      if (value != null) {
        TD.innerHTML = value === true || value === 'true' ? 'Yes' : '-';
      }
    }
  );

  function setLocalSheetData(data) {
    if (!data) {
      setSheetData([]);
    }
    const nextSheetData = [];

    for (let i = 0; i < data.length; i++) {
      const datum = data[i];

      const renderingValue = datum.rendering;

      if (renderingValue) {
        const isObject =
          typeof renderingValue === 'object' &&
          Object.keys(renderingValue).length;

        if (isObject) {
          const renderName = toTitleCase(
            `${renderingValue.first_name} ${renderingValue.last_name}`
          );

          datum.rendering = renderName;

          datum.old_rendering = renderingValue;
        } else {
          const providerOption = providerOptions.find(
            p => p.id === renderingValue
          );
          if (providerOption) {
            datum.rendering = providerOption.value;
          }
        }
      }
      if (datum.enc_dt) {
        const d = Date.parse(datum.enc_dt);
        if (d) {
          datum.enc_dt = format(new Date(d), 'MM-dd-yyyy');
        }
      }
      nextSheetData.push(datum);
    }
    //  des-object and set to setSheetData(local)
    setSheetData(nextSheetData);
  }

  // lifecycle
  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize();
      setWindowSize(size.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    registerCustomCells({ scale });
  }, [scale]);

  const [localHeader, setLocalHeader] = useState(auditSheetHeaders);
  const [localColumnData, setLocalColumnData] = useState(columnData);

  useEffect(() => {
    const reDestructuredData = sheet.data;

    // spreading in object
    const _reDestructuredData = reDestructuredData.map(sd => {
      const tempObj = { ...sd, ...sd?.additional_attributes };

      delete tempObj.additional_attributes;

      return tempObj;
    });

    // -- preparing dynamic column headers --
    const auditSheetColumnsheaders =
      prepareAuditSheetHeader(_reDestructuredData);
    setAuditSheetHeaders(auditSheetColumnsheaders);

    // -- preparing dynamic auditsheetcolummn --
    const dynamicColumnData = prepareAuditSheetColumn(
      _reDestructuredData,
      windowSize,
      view,
      providerOptions,
      isAuditedDollarDisabled,
      isProviderDollarDisabled
    );

    if (!!dynamicColumnData.length) {
      setColumnData(dynamicColumnData);
    }

    if (sheet?.data) {
      setLocalSheetData(sheet?.data);
    }
  }, [sheet?.data]);

  React.useEffect(() => {
    setColumnData(localColumnData);
    setAuditSheetHeaders(localHeader);
  }, [localHeader, localColumnData]);

  React.useEffect(() => {
    const _hilghtedColumn = findHighlightedColumn(columnData);
    const _dollorColumn = findDollorColumn(columnData);
    const _auditSheetkey = getAuditSheetKeys(columnData);
    const _agreeDisagree = findAgreeDisAgreeColumn(columnData);
    const _numericColumn = findNumericColumns(columnData);
    const _responseColumn = findResponseColumnIndex(columnData);

    setResponseColumn(_responseColumn);
    setAuditSheetColumnsKeys([..._auditSheetkey]);
    setHilightedColumn([..._hilghtedColumn]);
    setDollorColumn([..._dollorColumn]);
    setAgreeDisAgreeCol([..._agreeDisagree]);
    setNumericColumns(_numericColumn);
  }, [columnData.length]);

  useEffect(() => {
    const hot = hotRef.current.hotInstance;

    if (
      Object.keys(auditSheetContext.selectedCell).length &&
      sheetData.length
    ) {
      const { row, col } = auditSheetContext.selectedCell;
      const index = sheetData.findIndex(d => d.id === row);
      if (index !== -1) {
        hot.selectCell(index, col);
      }
    } else {
      hot.deselectCell();
    }
  }, [auditSheetContext.selectedCell]);

  function columnHighlight(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = AppColors.lightPink;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = 'center';
  }

  function commentCell(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.className = `htCommentCell`;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = col !== RENDERING_AS_COL && 'center';
    const COL = highlightedColumn; // [4, 5, 10, 11];
    if (COL.includes(col)) {
      td.style.background = AppColors.lightPink;
    }
    if (agreeDisAgreeCol.includes(col)) {
      // AGREE_DISAGREE_COL
      td.innerHTML = value === true ? 'Yes' : '-';
    }
    if (dollarColumn.includes(col)) {
      // DOLLAR_COL
      td.innerHTML = value ? `${value}` : null;
    }
  }

  const updateSheetData = (nextSheetData, row, rowIndex) => {
    if (rowIndex != null) {
      nextSheetData[rowIndex] = {
        row_id: null,
        file_name: currentUpload.upload_id,
        chart_id: currentUpload.id,
        ...nextSheetData[rowIndex],
        ...row,
      };
    } else {
      nextSheetData.push({ ...row, id: null });
    }

    return nextSheetData;
  };

  const onBeforeHotChange = change => {
    let nextSheetData = [...sheetData];
    change.map(changes => {
      const isNewRow = changes[0] >= sheetData.length;
      switch (changes[1]) {
        case 'srvcs_no':
          if (changes[3] === '-') {
            const row = {
              [changes[1]]: '',
              audited_dollar_value: industryCodes[changes[3]],
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            iaddState.on();
          } else if (
            Object.keys(industryCodes).length &&
            Object.keys(industryCodes).includes(changes[3])
          ) {
            const row = {
              [changes[1]]: changes[3],
              provider_dollar_value: industryCodes[changes[3]],
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            ipddState.on();
          } else {
            toast.showError({
              description: 'This Srvcs value is not available.',
            });
            const row = {
              srvcs_no: null,
              provider_dollar_value: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
          }
          break;
        case 'audited_code':
          if (changes[3] === '-') {
            const row = {
              [changes[1]]: '',
              audited_dollar_value: industryCodes[changes[3]],
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            iaddState.on();
          } else if (
            Object.keys(industryCodes).length &&
            Object.keys(industryCodes).includes(changes[3])
          ) {
            const row = {
              [changes[1]]: changes[3],
              audited_dollar_value: industryCodes[changes[3]],
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            iaddState.on();
          } else {
            toast.showError({
              description: 'This Audited Code is not available.',
            });
            const row = {
              audited_code: null,
              audited_dollar_value: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
          }
          break;
        case 'agree':
          const row = {
            ...nextSheetData[changes[0]],
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? true
                : false,
            disagree:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? false
                : true,
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            row,
            isNewRow ? null : changes[0]
          );
          if (
            changes[3].toLowerCase() === 'yes' ||
            changes[3].toLowerCase() === 'true'
          ) {
            nextSheetData[changes[0]].audited_code =
              nextSheetData[changes[0]].srvcs_no;
            nextSheetData[changes[0]].audited_rvu =
              nextSheetData[changes[0]].provider_rvu;
            nextSheetData[changes[0]].audited_dollar_value =
              nextSheetData[changes[0]].provider_dollar_value;
          }
          break;
        case 'disagree':
          const disagreeRow = {
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? true
                : false,
            agree:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? false
                : true,
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            disagreeRow,
            isNewRow ? null : changes[0]
          );
          break;
        case 'enc_dt':
          let data = changes[3];
          if (data) {
            const d = Date.parse(data);
            if (d) {
              const encDtRow1 = {
                [changes[1]]: format(new Date(d), 'MM-dd-yyyy'),
              };
              nextSheetData = updateSheetData(
                nextSheetData,
                encDtRow1,
                isNewRow ? null : changes[0]
              );
            } else {
              toast.showError({ description: 'Please, enter a valid date' });
            }
          } else {
            const encDtRow1 = {
              [changes[1]]: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              encDtRow1,
              isNewRow ? null : changes[0]
            );
          }

          break;
        default:
          const defaultRow = {
            [changes[1]]: changes[3] === '' ? null : changes[3],
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            defaultRow,
            isNewRow ? null : changes[0]
          );
          break;
      }
    });
    setLocalSheetData(nextSheetData);
    //update audit sheet on first cell update in a row
    if (change.some(d => !sheetData[d[0]].id)) {
      updateAuditSheet({ ...sheet, data: nextSheetData });
    } else {
      updateSheet(nextSheetData);
    }
    return false;
  };

  const agreeValidator = (value, callback) => {
    setTimeout(() => {
      if (value.toLowerCase() === 'yes' || value === '-') {
        callback(true);
      } else {
        callback(false);
      }
    }, 1000);
  };

  const hasComment = (row, col) => {
    if (row < sheetData?.length) {
      const colData = auditSheetColumnsKeys[col];
      //   const colData = AUDIT_SHEET_KEY[col];
      const rowId = sheetData[row]?.id;
      if (rowId && commentFlags && Object.keys(commentFlags).length) {
        if (commentFlags[rowId]?.includes(colData)) {
          return true;
        }
      }
    }
    return false;
  };

  function columnHeaders() {
    // auditSheetHeaders

    /*
    AUDIT_SHEET_HEADER.map((header, i) => {
      const fontSize = getScaledHeaderFontSize(scale);
      return `<div style="font-size: ${fontSize};"class="header">${header}</div>`;
    }),
    
    */
    return auditSheetHeaders.map((header, i) => {
      const fontSize = getScaledHeaderFontSize(scale);
      return `<div style="font-size: ${fontSize};"class="header">${header}</div>`;
    });
  }

  function contextOption() {
    return {
      async callback(key, selection, clickEvent) {
        // Common callback for all options
        onCommentClick(
          selection,
          { pageX: clickEvent.screenX, pageY: clickEvent.screenY },
          key === 'comments',
          false
        );

        if (key === 'comments') {
          const { row, column } = getRowColArray(selection[0], sheet);
          await getComments(uploadId, {
            row,
            column,
          });
        }
      },
      items: {
        row_above: {
          name: () => '<p>Insert row above</p>',
          disabled() {
            return view;
          },
        },
        row_below: {
          disabled() {
            return view;
          },
        },
        remove_row: {
          callback: function (key, selection) {
            const { rows } = getRowColIndex(selection[0], sheet);
            removeRows(rows);
          },
          disabled() {
            return view;
          },
        },
        cut: {
          disabled() {
            return view;
          },
        },
        copy: {
          disabled() {
            return view;
          },
        },
        // undo: {
        //   disabled() {
        //     return view;
        //   },
        // },
        comments: {
          name: 'Comments',
          hidden() {
            // `hidden` can be a boolean or a function
            // Hide the option when the first column was clicked
            const selectedIndex = this.getSelectedLast()[2];
            return !sheetData[selectedIndex].id; // `this` === hot
          },
        },
      },
    };
  }

  // onKeyPress
  function onKeyPress(e) {
    // column number
    let col = this.getSelectedLast()[1];
    var evt = e || window.event; // IE support

    var key = evt.charCode || evt.keyCode || 0;

    // check for cut and paste
    var isClipboard = false;
    var ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

    // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
    if (ctrlDown && evt.altKey) isClipboard = false;
    // Check for ctrl+c, v and x
    else if (ctrlDown && key === 67) isClipboard = true; // c
    else if (ctrlDown && key === 86) isClipboard = true; // v
    else if (ctrlDown && key === 88) isClipboard = true; // x
    else if (ctrlDown && evt.key === 'z') isClipboard = true;

    const isNumeric =
      NUMERIC_KEYS.includes(key) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105);

    switch (col) {
      case 0:
        if ((!isNumeric && !isClipboard) || e.shiftKey) {
          // prevent alpha characters
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        break;
      case 2:
        if ((!isNumeric && !isClipboard) || e.shiftKey) {
          // prevent alpha characters
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        break;
      default:
        break;
    }

    numericColumns.forEach(nc => {
      switch (col) {
        case nc:
          if ((!isNumeric && !isClipboard) || e.shiftKey) {
            // prevent alpha characters
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    });
  }

  // onMouseClick on cell
  function _afterOnCellMouseDown(e, p) {
    // e - event , p - cellCords[ row , column ]
    closeComment();

    const rowId = sheetData[p.row].id;

    if (p.col === responseColumn && rowId) {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        true
      );
    } else {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        false
      );
    }
  }

  function cellProps(row, col) {
    var cellProperties = {};

    const COL = highlightedColumn ?? [6, 14];
    if (COL.includes(col)) {
      cellProperties.renderer = columnHighlight; // uses lookup map
    }

    if (hasComment(row, col)) {
      cellProperties.renderer = commentCell; // uses comment map
    }

    return cellProperties;
  }

  function onPaste(data, coords) {
    const [startRow, startCol] = [coords[0].startRow, coords[0].startCol];
    const actualData = data[0];

    const rvuIndices = columnData.findIndex(col => col.data === 'provider_rvu');

    // Check if the destination column is 'enc_dt'
    if (startCol === rvuIndices) {
      // Check if any of the pasted values is not a number
      if (actualData.some(value => isNaN(+value))) {
        return false;
      }
    }

    // Continue with the paste operation for other columns or valid numeric values
    return true;
  }

  /** @type {import('handsontable/settings').GridSettings} */
  const hotSettings = {
    //sheet data
    data: sheetData,

    height: height,

    colHeaders: columnHeaders(),

    minSpareRows: 10,

    licenseKey: 'non-commercial-and-evaluation',

    //  right-click or context menu
    contextMenu: contextOption(),

    beforeChange: onBeforeHotChange,

    beforeKeyDown: onKeyPress,

    outsideClickDeselects: false,

    afterOnCellMouseDown: _afterOnCellMouseDown,

    cells: cellProps,

    beforePaste: onPaste,
  };

  /** @type {import('handsontable/settings').ColumnSettings[]} */
  const auditSheetColumnData = [
    {
      data: 'encounter_no',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'rendering',
      type: 'audit_sheet.dropdown_renderer',
      width: windowSize * 0.07,
      source: providerOptions.map(p => p.value),
      readOnly: true,
    },
    {
      data: 'enc_dt',
      type: 'date',
      dateFormat: 'MM-DD-YYYY',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
      datePickerConfig: {
        // First day of the week (0: Sunday, 1: Monday, etc)
        disableDayFn(date) {
          // Disable Sunday and Saturday
          return date > new Date();
        },
      },
    },
    {
      data: 'srvcs_no',
      type: 'text',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'provider_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'provider_dollar_value',
      type: 'audit_sheet.currency_renderer',
      width: windowSize * 0.05,
      readOnly: view || isProviderDollarDisabled,
    },
    {
      data: 'response',
      type: 'text',
      width: windowSize * 0.11,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'agree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'agreeRenderer',
    },
    {
      data: 'disagree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'agreeRenderer',
    },
    {
      data: 'audited_code',
      type: 'text',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'audited_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'audited_dollar_value',
      width: windowSize * 0.07,
      type: 'audit_sheet.currency_renderer',
      readOnly: view || isAuditedDollarDisabled,
    },
    {
      data: 'notes',
      type: 'text',
      width: windowSize * 0.14,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
  ];

  const hotTableDefaultSettings = {
    ...hotSettings,
    rowHeights: getScaledRowHeight(scale),
    stretchH: 'all',
    autoColumnSize: false,
    autoRowSize: false,
    wordWrap: true,
  };

  return (
    <HotTable
      ref={hotRef}
      columns={columnData}//auditSheetColumnData
      data={sheetData}
      settings={{
        ...hotTableDefaultSettings,
        manualColumnResize: true,
      }}
    ></HotTable>
  );
};
export default AuditSheet;
